.fc .fc-toolbar.fc-header-toolbar {
    height: 44px;
    margin: 0;
    padding: 0 20px 0 18px;
    border-bottom: 0.5px solid #D9D9D9;
    box-shadow: 0px 4px 4px 0px #0000001A;
}

.fc .fc-button-primary:disabled,
.fc .fc-button-primary {
    background-color: transparent !important;
    border-color: transparent;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
    text-transform: uppercase;
    /* font-family: poppins; */
    color: #494949;
    letter-spacing: -1.5%;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    border-color: transparent;
    color: #494949;
}

.fc .fc-button-primary:hover {
    border-color: transparent;
    color: #494949;
}

.fc .fc-button .fc-icon {
    color: #808080;
}
 
.fc-button-group:nth-child(2){
    width: 0.2px;
    height: 32px;
    background-color: #9F9C9C;
}
 
.fc--button {
    display: none !important;
}

.fc-theme-standard .fc-scrollgrid {
    border: 0px;
    padding: 0 30px;
} 

.fc .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section>td {
    height: 184px;  
}

.fc-scrollgrid-sync-inner {
    height: 100%;
    width: 100%;
    padding-top: 46px;
    padding-bottom: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.fc-theme-standard td, .fc-theme-standard th {
    border: none;
}

.fc-timegrid-slots tr {
    height: 42px;
}
.fc-timegrid-slots tr:nth-of-type(even) {
    border: 0.5px solid #6E6E6E;
}

.fc-timegrid-slots tr:nth-of-type(odd) {
    border: 0.5px solid #cccccc;
}
 
.fc-timegrid-slots tr:nth-child(1) {
    border-top: 0.5px solid #6E6E6E;
}

.fc .fc-scroller-liquid-absolute::-webkit-scrollbar {
    display: none;
  }

.fc-scroller::-webkit-scrollbar {
    display: none;
  }

.fc-col-header-cell-cushion:hover {
 text-decoration: none;
}

.fc .fc-timegrid-slot-minor {
    border: none;
}

.fc-timegrid-slots col {
    width: 66px !important;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: center;
}

.fc-timegrid-slots tr td:nth-child(1) {
    border-bottom: 1px solid #f5f5f5 !important; 
}

.fc-timegrid-slots tr td:nth-child(2) {
    box-shadow: 0px 2px 0px 0px #0000001A;
}

.fc .fc-view-harness {
    height: 934px !important;
}

.fc-timegrid-event-harness>.fc-timegrid-event {
    border-radius: 20px;
} 

.fc .fc-timegrid-col.fc-day-today {
    background-color: transparent;
}

.fc-timegrid-body {
    border-top: 0.5px solid #6E6E6E;
    box-shadow: 0px 2px 0px 0px #0000001A;
}

.fc-timegrid-axis {
    width: 44px !important;
    border-bottom: 1px solid #f5f5f5 !important;
}

.fc .fc-button-primary:disabled, .fc .fc-button-primary {
    text-transform: capitalize !important;
}

#date-input {
    appearance: none;
    -webkit-appearance: none; /* Safari and Chrome */
    -moz-appearance: none; /* Firefox */
    font-size: 12px;
    line-height: 12px;
    color: #494949;
    padding: 0;
    border: none;
    background-color: transparent;
    background: url(../DiYEditableView2/dropdownArrow.svg) no-repeat;
    width: 20px;
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-ms-clear {
    display: none; /* Remove the clear button in Chrome and Edge */
}

.fc-addActivity-button {
    margin-left: 18px !important;
}

input[type="text"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(../DiYEditableView2/dropdownArrow.svg) no-repeat;
    width: 20px;
    height: 100%;
}

.calendar-arrow {
    background: url(../DiYEditableView2/dropdownArrow.svg) no-repeat right;
    background-size: 12px;
  }

@media (max-width: 1280px) {
    .fc .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section>td {
        height: 140px;  
    }

    .fc-addActivity-button {
        margin-left: 140px !important;
    }
    .fc-timegrid-event-harness>.fc-timegrid-event {
        border-radius: 12px;
    } 
}

@media (max-width: 1024px) {
    .fc .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section>td {
        height: 100px;  
    }

    .fc-addActivity-button {
        margin-left: 0px !important;
    }

    .fc-theme-standard .fc-scrollgrid {
        padding: 0 16px;
    }

    .fc-scrollgrid-sync-inner {
        padding: 0 32px;
    }
}

@media (max-width: 767px) {

    .fc .fc-scrollgrid-section, .fc .fc-scrollgrid-section table, .fc .fc-scrollgrid-section>td {
        height: 68px;
    }
    .fc-timegrid-slot-label-cushion {
        font-size: 9px;
        line-height: 12px;
        font-weight: 300;
        padding: 0 !important;
    }

    .fc-direction-ltr .fc-timegrid-slot-label-frame {
        text-align: left;
    }
    .fc-scrollgrid-sync-inner {
        padding: 0;
        justify-content: start;
    }

    .fc .fc-col-header-cell-cushion{
        padding-top: 10px;
    }

    .fc-timegrid-slots col {
        width: 41px !important;
    }

    .fc-theme-standard .fc-scrollgrid {
       padding: 0;
    } 

    .fc .fc-toolbar.fc-header-toolbar {
        border: none;
        box-shadow: none;
        padding: 0;
    }

    .fc .fc-button-group {
        display: none;
    }

    .fc .fc-toolbar.fc-header-toolbar {
        height: auto;
    }

    .fc-addActivity-button {
        margin-left: 0 !important;
    }

    .fc .fc-button-primary:disabled, .fc .fc-button-primary {
        font-size: 9px !important;
        line-height: 11px !important;
        border: 0.5px solid #6E6E6E;
        border-radius: 5px;
        padding: 3px 7px 1px 7px;
    }

    .fc-toolbar-chunk:nth-child(3) {
        display: none;
    }

    .fc-timegrid-slots tr {
        height: 28.65px;
    }

    .secondLine-ellipses {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: wrap;
    }

    .fc .fc-view-harness {
        height: 100vh !important;
    }

    .fc-timegrid-event-harness>.fc-timegrid-event {
        border-radius: 5px;
    }
}

/* Mobile Responsive Style */

@media (max-width: 639px) {
    .ReactModal__Content {
        max-width: 420px !important;
        width: 100%;
        height: 100vh;
        background-color: #f5f5f5 !important;
        padding: 130px 0px 53px !important;
    }

    .mobile-modal-header {
        display: flex !important;
    }
}
.react-datepicker-wrapper .react-datepicker__input-container input{
    font-size: 12px;
    width:100%;
    height: 30px;
    border-bottom: 1px solid #050505a8;
}
.react-datepicker__input-container .react-datepicker__calendar-icon{
    position:absolute;
    right:0px;
    padding:0.5rem
}

.fc-header-toolbar {
    z-index: 10;
    position: relative;
}

.fc-day-disabled {
    background-color: white !important;
    opacity: 0.5;
    pointer-events: none;
}
