:root { 
--black_900_7f:#0000007f; 
--red_802:#cf1928; 
--cyan_900_99:#00487a99; 
--red_801:#d90429; 
--red_800:#c8102e; 
--light_blue_502:#00abea; 
--light_blue_501:#0e9bff; 
--blue_A200:#5392f9; 
--green_900:#008009; 
--light_blue_500:#0697ff; 
--black_900_3f:#0000003f; 
--light_blue_900:#00447c; 
--white_A700_99:#ffffff99; 
--deep_orange_A700:#d32011; 
--red_A700:#cc0000; 
--gray_700_33:#66666633; 
--gray_600:#6b6b6b; 
--gray_402:#bfbfbf; 
--gray_601:#808080; 
--gray_400:#cacaca; 
--gray_401:#c1bebe; 
--gray_500_4c:#9f9c9c4c; 
--amber_500:#febb02; 
--gray_800:#4f4f4f; 
--gray_801:#494949; 
--gray_700_7f:#6666667f; 
--gray_200:#f0f0f0; 
--yellow_A700:#ffd300; 
--blue_50:#e4f4ff; 
--indigo_400:#6c6bbd; 
--bluegray_401:#898686; 
--light_green_A700_84:#72C044; 
--bluegray_400:#878787; 
--blue_300:#5da4d7; 
--blue_301:#75a2e3; 
--cyan_800:#007b84; 
--white_A701:#fefefe; 
--white_A700:#ffffff; 
--light_green_A700_c4:#3ac424c4; 
--green_201:#93df98; 
--green_200:#97e59c; 
--light_green_A400:#72C044; 
--red_700:#e21836; 
--blue_A700:#0669ff; 
--light_blue_601:#0099df; 
--light_blue_600:#0976B5; 
--light_blue_401:#2fb8ed; 
--light_blue_400:#2ca7ff; 
--gray_50:#f9f9fa; 
--amber_A400:#ffcc00; 
--light_blue_800:#0071c2; 
--green_202:#ade2b5; 
--black_900:#000000; 
--black_901:#110f0d; 
--bluegray_100_6c:#d9d9d96c; 
--black_900_26:#00000026; 
--gray_501:#949494; 
--gray_502:#9f9c9c; 
--gray_700:#666666; 
--gray_500:#999696; 
--gray_901:#2d2327; 
--indigo_900_c4:#173042; 
--amber_600:#feba02; 
--gray_701:#666162; 
--gray_503:#929191; 
--blue_600:#1a8fdd; 
--gray_900:#231f20; 
--gray_702:#595656; 
--gray_504:#999999; 
--gray_501:#d9d9d9; 
--gray_101:#f3f2f3; 
--green_50:#e7fde9; 
--gray_300:#e6e6e6; 
--gray_100:#f5f5f5; 
--bluegray_103:#cecaca; 
--bluegray_102:#d6d4d4; 
--bluegray_101:#cccccc; 
--indigo_902:#012169; 
--gray_200_ce:#e7e7e7ce; 
--indigo_903:#013580; 
--indigo_900:#000066; 
--indigo_901:#003580;
--indigo_905:#173042;
--white_A700_e5:#ffffffe5; 
--gray_500:#9a9a9a; 
--blue_500:#18a0fb; 
--gray_800:#3a3a3a; 
--bluegray_100:#d3d3d3; 
--green_900:#01752d; 
--green_800:#008d36; 
--red_400:#f24949; 
--black_900_0c:#0000000c; 
--teal_300:#45b9b4; 
--teal_500:#019d90; 
--black_900:#000000; 
--teal_900:#012e40; 
--white_A701:#fdfdfd; 
--white_A700:#ffffff; 
--cyan_700:#00a19a; 
}