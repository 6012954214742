@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 5px solid #e6e6e6; /* Light grey */
    border-top: 5px solid #00A19A; /* Green */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }