body {
    background-color: #F1F1F1;
}

  .error{
    font-size:25px;
    text-align:center;
    font-family:'montserrat';
    margin-top:-40px;
  }

  .container
  {
    width:800px;
    margin:0 auto;
    margin-top: 15%;
    background-color: #F1F1F1;
    ;
  }

