/* Styles for larger screens (web) */
.loader-container21 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: 17vh;
    /* Full viewport width */
    position: absolute; /* Add relative positioning */
  }
  .loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #00A19A;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-text {
    margin-top: 10px;
    font-size: 18px;
    color: #00A19A;
  }
  
  /* Styles for smaller screens (e.g., mobile devices) */
  @media (max-width: 767px) {
    .loader-container21 {
      height: 100vh; /* Use full viewport height on mobile */
      justify-content: flex-start; /* Align content to the top */
      align-items: flex-start; /* Align items to the top */
      position: fixed;
      margin-left: 10vh;
    }
  
    .loader {
      width: 30px;
      height: 30px;
    }
  
    .loading-text {
      font-size: 16px;
    }
  }