*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container{
    display:flex;
}
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    background: #D3DDDC;
    color: #fff;
    height: 100vh;
    width: 200px;
    transition: all 0.5s;
}
.top_section{
    display: flex;
    align-items:start;
    padding:20px 15px;
}
.logo{
    font-size: 20px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 50px;
    color: #00A19A;
}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover{
    background:lightskyblue;
    /* color: #000; */
    transition: all 0.5s;
}
.active{
    background: #fff;
    color: #000;
}
.icon, .link_text{
    font-size: 20px;
    color: #00A19A;

}
